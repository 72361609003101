<template>
  <div class="lottery-long">
    <div class="header">
      <div class="title-box">
        <p class="title">双面长龙</p>
        <intro-default cont="双面长龙统计N期长龙每天出现的次数。长龙，指某形态（大、小、单、双、龙、虎）连续出现的情况。2期长龙，3期长龙……N期长龙：指某形态连续N次出现，然后中止。[ 大/小长龙 ] 可查看今日某位置连续开出n期号码均为大/小，共出现多少次；[ 单/双长龙 ] 可查看今日某位置连续开出n期号码均为单/双，共出现多少次；[ 龙/虎长龙 ] 可查看今日1到5名连续开出n期均为龙/虎，共出现多少次。"></intro-default>
      </div>
      <div class="search-box color-setting">
        <div class="settings">
          <div class="item">
            <div class="input">
              <span>参考设置:</span>大于<input type="number"
                v-model.number="option.num">时为<i :style="{'background-color': option.color}"></i>色
            </div>
          </div>
          <div class="confirm">
            <div @click="colorSetting">确定</div>
          </div>
        </div>
      </div>
    </div>
    <div class="lz_cont">
      <div class="table">
        <el-table :data="data" border style="width: 100%" highlight-current-row header-cell-class-name="table-header-cell" cell-class-name="table-cell" row-class-name="table-row" size="small" row-key="id" id="table">
          <el-table-column label="位置" align="center" prop="location"></el-table-column>
          <el-table-column v-for="(item, key) in head_list['list']" :key="key" :label="item" align="center">
            <template #default="scope">
              <span v-if="scope.row[head_list['props'][key]['label']]" :style="{color: scope.row[head_list['props'][key]['value']] > option.value ? '#FA3E3E' : '#282828'}">
                【{{ scope.row[head_list['props'][key]['label']] }}】{{ scope.row[head_list['props'][key]['value']] }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Loading } from 'element-ui'
import lotteryCodeLength from '@/src/config/lottery-code-length'

export default {
  name: 'LotteryLong',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    head_list () {
      const len = lotteryCodeLength(this.code)
      if (len === 10) {
        return {
          list: ['大小', '单双', '龙虎'],
          props: [this.types.big_small, this.types.single_double, this.types.loong_tiger]
        }
      }
      if (len === 8) {
        return {
          list: ['大小', '单双', '尾大小', '合单双', '龙虎'],
          props: [this.types.big_small, this.types.single_double, this.types.tail_big_small, this.types.join_single_double, this.types.loong_tiger]
        }
      }
      if (len === 7) {
        return {
          list: ['大小', '单双', '尾大小', '合单双'],
          props: [this.types.big_small, this.types.single_double, this.types.tail_big_small, this.types.join_single_double]
        }
      }
      if (len === 6) {
        return {
          list: ['阴阳', '禽兽', '前后'],
          props: [this.types.yin_yang, this.types.animals, this.types.before_after]
        }
      }
      if (len === 5) {
        return {
          list: ['大小', '单双', '龙虎'],
          props: [this.types.big_small, this.types.single_double, this.types.loong_tiger]
        }
      }
      if (len === 4) {
        return {
          list: ['大小', '单双', '尾大小', '合单双'],
          props: [this.types.big_small, this.types.single_double, this.types.tail_big_small, this.types.join_single_double]
        }
      }
      if (len === 3) {
        if (this.code === 'pcdd') {
          return {
            list: ['大小', '单双'],
            props: [this.types.big_small, this.types.single_double]
          }
        } else {
          return {
            list: ['大小', '单双', '二同号三不同'],
            props: [this.types.big_small, this.types.single_double, this.types.twoThreeIdentical]
          }
        }
      }
      return { list: [], props: [] }
    }
  },
  data () {
    return {
      option: {
        num: 10,
        value: 10,
        color: '#FA3E3E'
      },
      data: [],
      loading: null,
      types: {
        'big_small': {
          'label': 'big_small_value',
          'value': 'big_small'
        },
        'single_double': {
          'label': 'single_double_value',
          'value': 'single_double'
        },
        'tail_big_small': {
          'label': 'tail_big_small_value',
          'value': 'tail_big_small'
        },
        'join_single_double': {
          'label': 'join_single_double_value',
          'value': 'join_single_double'
        },
        'loong_tiger': {
          'label': 'loong_tiger_value',
          'value': 'loong_tiger'
        },
        'yin_yang': {
          'label': 'yin_yang_value',
          'value': 'yin_yang'
        },
        'animals': {
          'label': 'animals_value',
          'value': 'animals'
        },
        'before_after': {
          'label': 'before_after_value',
          'value': 'before_after'
        },
        'twoThreeIdentical': {
          'label': 'twoThreeIdentical_value',
          'value': 'twoThreeIdentical'
        }
      }
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.requestGetHistory()
    }
  },
  mounted () {
    this.requestGetHistory()
  },
  methods: {
    colorSetting () {
      const obj = { value: this.option.num }
      Object.assign(this.option, obj)
    },
    refresh () {
      this.requestGetHistory()
    },
    requestGetHistory () {
      this.showTableLoading()
      this.$api.longDragon(this.code).then((res) => {
        this.data = res.data
        this.hideTableLoading()
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading () {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading () {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.lottery-long {
  > .header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    > .title-box {
      display: flex;
      align-items: center;
      height: 49px;

      > .title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }
  }
  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .types {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #F9F9F9;
    display: flex;
    align-items: center;

    > li {
      cursor: pointer;
      position: relative;
      padding: 0 10px;
      font-size: 14px;
      font-weight: bold;
      color: #A1A1A1;
      min-width: 108px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-right: 1px solid #E3E3E5;
      border-bottom: 1px solid #E3E3E5;
      &.active {
        color: #FA3E3E;
        background-color: #fff;
        border-top: 2px solid #FA3E3E;
        border-bottom: none;
      }
    }
    .types_empty {
      flex: 1;
      border-right: none;
    }
  }

  .ranks {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    padding-left: 12px;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 26px;

    > li {
      cursor: pointer;
      list-style: none;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      color: #222;
      min-width: 68px;
      height: 26px;
      border-radius: 50px;

      &.active {
        color: #FA3E3E;
        background-color: #FEEBEB;
      }
    }
  }

}
.color-setting {
    flex: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    > .head {
      margin-right: 18px;
      > p {
        font-size: 14px;
        font-weight: bold;
        color: #222;
      }
    }

    > .settings {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > .item {
        & + .item {
          margin-left: 40px;
        }
        display: flex;
        align-items: center;

        > .input {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #959595;
          flex-grow: 1;

          span {
            margin-right: 6px;
            color: #FA3E3E;
          }

          input {
            width: 32px;
            height: 24px;
            color: #959595;
            border: 1px solid #E3E3E5;
            border-radius: 4px;
            box-sizing: border-box;
            text-align: center;
            background: none;
            outline: none;
            margin-left: 6px;
            margin-right: 6px;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
            &[type="number"] {
              -moz-appearance: textfield;
            }
          }

          i {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin-left: 6px;
            margin-right: 6px;
          }
        }

        > .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 9px;
          border-left: 1px solid rgba(216, 216, 216, 0.5);

          > img {
            cursor: pointer;
          }
        }
      }
      > .confirm {
        margin-left: 36px;
        display: flex;
        justify-content: flex-end;
        >div {
          width: 86px;
          height: 28px;
          background: #FA3E3E;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
 
</style>

<style>
 .lottery-long .table {
  margin-top: 20px;
}

.lottery-long .table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  height: 40px;
}

.lottery-long .table .table-header-cell>.cell {
  padding: 0;
}

.lottery-long .table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 40px;
}

.lottery-long .table .table-cell>.cell {
  padding: 0;
}

.lottery-long .table .table-row:hover>td,
.lottery-long .table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
